import { useEffect, useRef } from 'react';

type UseOnMountCallback = () => void;

/**
 * Run a function once on mount
 * @param onMountCallback
 * @returns
 */
export const useOnMount = (onMountCallback?: UseOnMountCallback) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      if (onMountCallback) {
        onMountCallback();
      }

      isMounted.current = true;
    }
  }, []);

  return {
    isMounted: isMounted.current,
  };
};
