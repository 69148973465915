import cn from 'classnames';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { CoreRichTextData } from '../../../types/components';

import styles from './CoreRichText.module.scss';

export type CoreRichTextProps = CoreRichTextData;

const CoreRichText = (props: CoreRichTextProps): JSX.Element => {
  const { header, subHeader, indent, richText } = props.fields;

  return (
    <div
      className={cn(
        styles.wrapper,
        'spacer-L',
        { [styles['wrapper--indented']]: indent },
        'container-12'
      )}
    >
      <div className={styles.main}>
        {(header || subHeader) && (
          <div className={styles['heading__wrapper']}>
            <Text tag="h2" className={styles['main-heading']} field={header} />
            <Text className={styles['sub-heading']} field={subHeader} />
          </div>
        )}
        <RichText field={richText} />
      </div>
    </div>
  );
};

export default CoreRichText;
