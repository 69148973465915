import {
  Level,
  LevelLabel,
  debugLevel,
  errorLevel,
  infoLevel,
  traceLevel,
  warnLevel,
} from './types';

export const getLevelFromLabel = (label?: LevelLabel, defaultLevel: Level = infoLevel): Level => {
  if (!label) {
    return defaultLevel;
  }
  const levels = [traceLevel, debugLevel, infoLevel, warnLevel, errorLevel];
  const level = levels.find((level) => level.label === label);
  return level || defaultLevel;
};

export const isPlainObject = (obj: unknown): obj is Record<string, unknown> => {
  if (typeof obj !== 'object' || typeof obj === 'undefined') {
    return false;
  }
  return Object.getPrototypeOf(obj) === Object.prototype;
};

export const isBrowser = (): boolean => typeof window !== 'undefined';
