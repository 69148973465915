import cn from 'classnames';
import { useId } from 'react';

import Chevron from 'assets/icons/chevron.svg';

import Text from 'components/BaseHelpers/Text';

import styles from './SelectDropDown.module.scss';

export type SelectDropDownProps = {
  label: string;
  name: string;
  options: { label: string }[];
  className?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const SelectDropDown = ({
  label,
  name,
  options,
  className,
  required = false,
  placeholder,
  value = '',
  onChange,
}: SelectDropDownProps): JSX.Element => {
  const labelValue = required ? label + ' (required)' : label;
  const selectedLabel = options.find((option) => option.label === value)?.label || placeholder;

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const selectId = useId();

  return (
    <label htmlFor={selectId} className={cn(styles.main, className)}>
      <div className={styles.container}>
        <Text field={selectedLabel} className={styles['selected']} />
        <Chevron className={styles.icon} />
        <select
          id={selectId}
          className={styles.select}
          name={name}
          value={value}
          required={required}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.label} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <span className={styles['label']}>{labelValue}</span>
    </label>
  );
};

export default SelectDropDown;
