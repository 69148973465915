import { IncomingHttpHeaders } from 'http';

export const traceLevel = { label: 'trace', value: 10 } as const;
export const debugLevel = { label: 'debug', value: 20 } as const;
export const infoLevel = { label: 'info', value: 30 } as const;
export const warnLevel = { label: 'warn', value: 40 } as const;
export const errorLevel = { label: 'error', value: 50 } as const;

export type Level =
  | typeof traceLevel
  | typeof debugLevel
  | typeof infoLevel
  | typeof warnLevel
  | typeof errorLevel;

export type LevelLabel = Level['label'];

export type Data = unknown;

export type FormatterContext = {
  data: Data;
  level: Level;
  metaData: Record<string, unknown>;
};

export type TransportContext = {
  rawData: Data;
  formattedData: Record<string, unknown>;
  level: Level;
};

export type Transport = {
  level?: LevelLabel;
  log: (context: TransportContext) => unknown;
};

export type Formatter = {
  format: (context: FormatterContext) => unknown;
};

export type ApiServiceClient = {
  sendEvent: (data: unknown) => Promise<void>;
};

export type LogglyApiClientOptions = {
  token: string;
  subdomain: string;
  tags: string;
};

export type ApiRequest = {
  body?: Record<string, unknown>;
  headers: IncomingHttpHeaders & {
    'CF-Ray'?: string;
  };
  ip?: string;
  method?: string | undefined;
  url?: string;
};

export type ApiResponse = {
  status: (status: number) => ApiResponse;
  json: (data: unknown) => void;
};
