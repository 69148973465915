import cn from 'classnames';

import Chevron from 'assets/icons/chevron.svg';

import styles from './SliderNavigation.module.scss';

export type SliderNavigationProps = {
  onPrev: () => void;
  onNext: () => void;
  activeIndex: number;
  totalSlides: number;
};

const SliderNavigation = ({
  onPrev,
  onNext,
  activeIndex,
  totalSlides,
}: SliderNavigationProps): JSX.Element => {
  return (
    <div className={cn(styles.main)}>
      <ul className={cn(styles['pagination'])}>
        {Array.from({ length: totalSlides }, (_, index) => (
          <li
            key={index}
            className={cn(styles.bullet, {
              [styles['bullet--active']]: index === activeIndex,
            })}
          />
        ))}
      </ul>
      <div className={styles['navigation']}>
        <button
          aria-label="Previous slide"
          className={cn(styles[`icon`], styles[`prev`])}
          onClick={onPrev}
        >
          <Chevron className={styles['chevron']} />
        </button>
        <button
          aria-label="Next slide"
          className={cn(styles[`icon`], styles[`next`])}
          onClick={onNext}
        >
          <Chevron className={styles['chevron']} />
        </button>
      </div>
    </div>
  );
};

export default SliderNavigation;
