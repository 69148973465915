import cn from 'classnames';

import { CoreGlobalNavigationFooterData } from 'src/types/components';

import Insta from '../../../assets/icons/insta.svg';
import LinkedIn from '../../../assets/icons/linkedin.svg';
import X from '../../../assets/icons/x.svg';
import Link from '../Link';

import styles from './SocialSVG.module.scss';

export type SocialSVGProps = CoreGlobalNavigationFooterData['fields']['socialMediaNav'][number] & {
  color?: 'black' | 'white';
};

const SocialSVG = (props: SocialSVGProps): JSX.Element => {
  const { color = 'black' } = props;

  const renderSVG = (): JSX.Element | null => {
    const svgClassName = styles['svg'];

    switch (props.platform) {
      case 'linkedIn':
        return <LinkedIn className={svgClassName} />;
      case 'x':
        return <X className={svgClassName} />;
      case 'instagram':
        return <Insta className={svgClassName} />;
      default:
        return <></>;
    }
  };

  return (
    <Link
      aria-label={`link to ${props.platform}`}
      className={cn(styles.main, styles[color])}
      href={props.url}
      target="_blank"
    >
      {renderSVG()}
    </Link>
  );
};

export default SocialSVG;
