import styles from './Burger.module.scss';

export type BurgerProps = {
  menuOpen: boolean;
};

const Burger = (props: BurgerProps): JSX.Element => {
  const { menuOpen } = props;

  const getBurgerClass = (menuOpen: boolean) =>
    menuOpen
      ? `${styles['burger-bar']} ${styles['clicked']}`
      : `${styles['burger-bar']} ${styles['unclicked']}`;

  return (
    <div className={styles.main}>
      <div className={getBurgerClass(menuOpen)}></div>
      <div className={getBurgerClass(menuOpen)}></div>
      <div className={getBurgerClass(menuOpen)}></div>
    </div>
  );
};

export default Burger;
