import Script from 'next/script';

import { useRouteContext } from '../../../hooks/useRouteContext';

const OneTrust = (): JSX.Element | null => {
  const { routeData } = useRouteContext();

  const env = routeData?.envVars.environment;

  const oneTrustPath =
    env === 'PROD'
      ? '0192587f-450e-72d3-b057-e5779b53fc39'
      : '0192587f-450e-72d3-b057-e5779b53fc39-test';

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={oneTrustPath}
      ></Script>

      <Script
        id="one-trust-2"
        type="text/javascript"
        strategy="lazyOnload"
      >{`function OptanonWrapper() {}`}</Script>

      {/* These are part of the oneTrust script that was provided by the client, incase it is needed I'm leaving it in here for easy access. */}

      {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button> */}
      {/* <div id="ot-sdk-cookie-policy"></div> */}
    </>
  );
};

export default OneTrust;
