import { Popover, Portal } from '@ark-ui/react';
import cn from 'classnames';
import { useState } from 'react';

import { useOnResize } from 'src/hooks/useOnResize';

import { CoreGlobalNavigationHeaderData } from '../../../../../types/components';
import Burger from '../Burger/Burger';
import MobileContent from '../MobileContent/MobileContent';

import styles from './MobileMenu.module.scss';

export type CoreGlobalNavigationHeaderFieldsWithoutLogo = Pick<
  CoreGlobalNavigationHeaderData['fields'],
  'primaryNav' | 'accountButton' | 'contact' | 'socialMediaNav'
>;

export type MobileMenuProps = CoreGlobalNavigationHeaderFieldsWithoutLogo;

const MobileMenu = (props: MobileMenuProps): JSX.Element => {
  const { setOnResizeFn } = useOnResize();
  const [menuOpen, setMenuOpen] = useState(false);

  setOnResizeFn(() => {
    closeMenu();
  });

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className={cn(styles.main)}>
      <Popover.Root modal closeOnInteractOutside={false} open={menuOpen} closeOnEscape>
        <Popover.Trigger
          className={styles['mobile-menu-toggle']}
          aria-label="Open Mobile Menu"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Burger menuOpen={menuOpen} />
        </Popover.Trigger>

        <Portal>
          <Popover.Content asChild>
            <div className={cn(styles.content, { [styles.open]: menuOpen })}>
              <div className={styles['hidden-close']}>
                <Popover.CloseTrigger
                  onClick={() => setMenuOpen(false)} // Manually close the menu
                  className={styles['hidden-close-trigger']}
                  aria-label="Close Mobile Menu"
                ></Popover.CloseTrigger>
              </div>
              <div className={styles['wrapper']}>
                {/* Mobile menu content */}
                <MobileContent closeMenu={closeMenu} {...props} />
              </div>
            </div>
          </Popover.Content>
        </Portal>
      </Popover.Root>
    </div>
  );
};

export default MobileMenu;
