import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, Controller } from 'react-hook-form';

import { useRouteContext } from 'src/hooks/useRouteContext';

import PillCTA from 'components/BaseHelpers/PillCTA';
import Text from 'components/BaseHelpers/Text';

import { CoreFormData } from '../../../types/components';
import InputField from './components/InputField';
import SelectDropDown from './components/SelectDropDown';

import styles from './CoreForm.module.scss';

type FormState = 'default' | 'success' | 'error';

export type CoreFormProps = CoreFormData;

const CoreForm = (props: CoreFormProps): JSX.Element => {
  const { header, formFields, submitButtonLabel, id, successMessage, errorMessage } = props.fields;

  const [formState, setFormState] = useState<FormState>('default');

  const [captchaValue, setCaptchaValue] = useState<string>('');

  const { routeData } = useRouteContext();

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const envVars = routeData?.envVars;

  // Giving empty strings as default values so that upon resetting form, they all return to ''
  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.label] = '';
      return acc;
    },
    {} as Record<string, string>
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, dirtyFields },
  } = useForm({
    mode: 'onChange', // validates form upon change
    defaultValues,
  });

  const atLeastOneField = Object.keys(dirtyFields).length > 0;

  const onCaptchaChange = (value: string) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    if (formState === 'success') {
      reset(defaultValues);
    }
  }, [formState, reset]);

  const onSubmit = async (data: Record<string, string>) => {
    const serializedData = {
      formId: id,
      elements: formFields.map((field) => ({
        label: field.label,
        type: field.type,
        value: data[field.label] || '',
      })),
      captcha: captchaValue,
    };

    try {
      const response = await fetch('/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(serializedData),
      });

      if (response.ok) {
        setFormState('success');
        setCaptchaValue('');
        recaptchaRef.current?.reset();
      } else {
        setFormState('error');
      }
    } catch (error) {
      setCaptchaValue('');
      console.error('Error submitting form:', error);
      setFormState('error');
    }
  };

  return (
    <div className={cn(styles.main, 'spacer', 'container-10')}>
      <Text tag="h2" field={header} className={styles.header} />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {formFields.map((field, index) => {
          switch (field.type) {
            case 'input':
              return (
                <Controller
                  key={index}
                  name={field.label}
                  control={control}
                  rules={{ required: field.required }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      label={field.label}
                      placeholderText={field.placeholderText}
                      required={field.required}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                    />
                  )}
                />
              );
            case 'input_email':
              return (
                <Controller
                  key={index}
                  name={field.label}
                  control={control}
                  rules={{ required: field.required, pattern: /^\S+@\S+$/i }} // Email validation
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      type="email"
                      label={field.label}
                      placeholderText={field.placeholderText}
                      required={field.required}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                    />
                  )}
                />
              );
            case 'text_area':
              return (
                <Controller
                  key={index}
                  name={field.label}
                  control={control}
                  rules={{ required: field.required }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      type="textarea"
                      label={field.label}
                      placeholderText={field.placeholderText}
                      required={field.required}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                    />
                  )}
                />
              );
            case 'drop_down':
              return (
                <Controller
                  key={index}
                  name={field.label}
                  control={control}
                  rules={{ required: field.required }}
                  render={({ field: { onChange, value } }) => (
                    <SelectDropDown
                      options={field.dropDownSelectOptions}
                      label={field.label}
                      name={field.label}
                      placeholder={field.placeholderText}
                      required={field.required}
                      onChange={(val) => onChange(val)}
                      value={value}
                    />
                  )}
                />
              );
            default:
              return <></>;
          }
        })}
        <div className={styles['submit-container']}>
          <div className={styles['recaptcha']}>
            <div className={styles['recaptcha-input']}>
              {!captchaValue && (
                <input
                  className={styles['recaptcha-hidden-input']}
                  name="Recaptcha"
                  aria-label="Recaptcha"
                  required
                  type="checkbox"
                />
              )}
              {envVars?.recaptchaKey && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={envVars.recaptchaKey || ''}
                  onChange={onCaptchaChange}
                  onError={() => setCaptchaValue('')}
                  onExpired={() => console.log('test')}
                />
              )}
            </div>
            <PillCTA
              greyed={!isValid || !atLeastOneField || !captchaValue}
              className={styles.cta}
              tag="button"
              type="submit"
              label={submitButtonLabel}
              onClick={() => {}}
            />
          </div>
          {formState !== 'default' && (
            <Text
              field={formState === 'success' ? successMessage : errorMessage}
              className={cn(styles['form-submission'], {
                [styles['form-submission--error']]: formState === 'error',
              })}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default CoreForm;
