import { PropsWithChildren, createContext, useContext } from 'react';

import { RouteHandlerData } from 'src/types/model';

export interface RouteContextValue {
  routeData: RouteHandlerData | undefined;
  // Add more global context values and their associated types here
}

export const RouteContext = createContext<RouteContextValue | undefined>(undefined);

export const RouteContextProvider = ({
  routeData,
  children,
}: PropsWithChildren<{ routeData: RouteHandlerData | undefined }>) => {
  return <RouteContext.Provider value={{ routeData }}>{children}</RouteContext.Provider>;
};

export const useRouteContext = () => {
  const context = useContext(RouteContext);
  if (context === undefined) {
    throw new Error('useRouteContext must be used within a RouteContextProvider');
  }
  return context;
};
