import cn from 'classnames';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import PillCTA from 'components/BaseHelpers/PillCTA';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { CoreTextBlockData } from '../../../types/components';

import styles from './CoreTextBlock.module.scss';

export type CoreTextBlockProps = CoreTextBlockData;

const CoreTextBlock = (props: CoreTextBlockProps): JSX.Element => {
  const { blocks } = props.fields;

  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  if (!blocks.length) {
    return <></>;
  }

  return (
    <GsapTimelineProvider timeline={timeline}>
      <div ref={scrollTriggerRef} className={cn(styles.main, 'spacer', 'container-10')}>
        {blocks.map((item, index) => {
          return (
            <AnimateInContainer position={index * 0.4} key={index} className={styles.animate}>
              <div className={cn(styles.card, styles[item.background])}>
                <Text className={styles.header} field={item.headline} />
                <RichText className={styles.description} field={item.description} />
                <PillCTA
                  label={item.cta?.label}
                  className={styles.cta}
                  href={item.cta?.href}
                  target={item.cta.target}
                  tag="a"
                />
              </div>
            </AnimateInContainer>
          );
        })}
      </div>
    </GsapTimelineProvider>
  );
};

export default CoreTextBlock;
