import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from 'react';

import { ImageData, LinkData } from 'src/types/components';

import { gsap, CustomEase } from '../../../utils/gsap';
import Card from '../Card/Card';
import PillCTA from '../PillCTA';
import Text from '../Text';

import styles from './TabsCardsContainer.module.scss';

export type TabsCardsContainerProps = {
  active: boolean;
  headline: string;
  cta: LinkData;
  cards: {
    image?: ImageData;
    largeImage: boolean;
    eyebrow: string;
    title: string;
    description: string;
    date: string;
    cta: LinkData;
    videoIndicator: boolean;
    seriesTitle: string;
    primaryTag: string;
  }[];
};

const TabsCardsContainer = (props: TabsCardsContainerProps): JSX.Element => {
  const { headline, cta, cards, active = false } = props;

  const [played, setPlayed] = useState<boolean>(false);

  const headingRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const animDuration = 1.1;

  const animConfig = {
    autoAlpha: 1,
    y: 0,
    ease: 'cubic',
    duration: animDuration,
  };

  const tl = useRef<GSAPTimeline | null>(null);

  useGSAP(
    () => {
      tl.current = gsap.timeline({
        paused: true,
        onComplete: () => setPlayed(true),
      });

      CustomEase.create('cubic', '0.65,0,0.35,1');
      gsap.set(headingRef.current, { autoAlpha: 0, y: 65 });
      gsap.set(buttonRef.current, { autoAlpha: 0, y: 65 });
      gsap.set(`.${styles['item']}`, { autoAlpha: 0, y: 65 });

      const headingAnim = gsap.to(headingRef.current, {
        ...animConfig,
      });

      const containerAnim = gsap.to(`.${styles['item']}`, {
        ...animConfig,
        stagger: cards.length < 7 ? 0.4 : 0,
      });

      const buttonAnim = gsap.to(buttonRef.current, {
        ...animConfig,
      });

      tl.current.add(headingAnim);
      tl.current.add(containerAnim, headline ? animDuration : 0);
      tl.current.add(buttonAnim, headline ? animDuration : 0);
    },
    { scope: containerRef }
  );

  useEffect(() => {
    if (active && !played) {
      tl.current?.play();
    }
    return;
  }, [active, played]);

  return (
    <div ref={containerRef}>
      <div className={styles.main}>
        <div ref={headingRef}>
          <Text tag="h4" field={headline} className={styles.headline} />
        </div>
        <ul className={styles.container}>
          {cards.map((card, index) => {
            return (
              <li key={index} className={styles.item}>
                <Card {...card} />
              </li>
            );
          })}
        </ul>
        <div ref={buttonRef} className={styles.cta}>
          <PillCTA tag="a" {...cta} />
        </div>
      </div>
    </div>
  );
};

export default TabsCardsContainer;
