import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Image from 'components/BaseHelpers/Image';
import PillCTA from 'components/BaseHelpers/PillCTA';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { CoreFeaturedMediaData } from '../../../types/components';

import styles from './CoreFeaturedMedia.module.scss';

export type CoreFeaturedMediaProps = CoreFeaturedMediaData;

const CoreFeaturedMedia = (props: CoreFeaturedMediaProps): JSX.Element => {
  const { header, description, mediaType, image, video } = props.fields;
  const [videoPlayed, setVideoPlayed] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setVideoPlayed(true);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleVideoEnd = () => {
        setVideoPlayed(false);
      };

      videoElement.addEventListener('ended', handleVideoEnd);
      return () => {
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
    return;
  }, []);

  if ((mediaType === 'image' && !image?.src) || (mediaType === 'video' && !video?.url))
    return <></>;

  const hasIntro = Boolean(header || description);

  return (
    <div ref={scrollTriggerRef} className={'spacer'}>
      <div className={styles.main}>
        <GsapTimelineProvider timeline={timeline}>
          <div className={styles['img-container']}>
            <div
              className={cn(styles['media-container'], {
                [styles['media-container--intro']]: hasIntro,
                [styles['media-container--video']]: mediaType === 'video',
                [styles['media-container--video-played']]: mediaType === 'video' && videoPlayed,
                'container-12': !hasIntro,
              })}
            >
              {mediaType === 'image' && image && (
                <AnimateInContainer position={0} checkForImages={true}>
                  <Image fill={true} {...image} />
                </AnimateInContainer>
              )}
              {mediaType === 'video' && video?.url && (
                <AnimateInContainer checkForImages={true} position={0}>
                  <video
                    ref={videoRef}
                    controls={videoPlayed}
                    playsInline
                    className={cn(styles.media, styles['media--video'])}
                  >
                    <source src={video.url} type="video/mp4" />
                  </video>
                  <PillCTA
                    theme="dark"
                    icon="play"
                    tag="button"
                    className={cn(styles.cta, { [styles['cta--played']]: videoPlayed })}
                    label="PLAY VIDEO"
                    onClick={handlePlayVideo}
                  />
                </AnimateInContainer>
              )}
            </div>
          </div>
          <div className={cn('container-12', styles.container)}>
            <AnimateInContainer position={0} stagger={0.4} className={styles.intro}>
              <Text tag="h2" field={header} className={styles.header} />
              <RichText field={description} className={styles.description} />
            </AnimateInContainer>
          </div>
        </GsapTimelineProvider>
      </div>
    </div>
  );
};

export default CoreFeaturedMedia;
