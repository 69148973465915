import { ApiServiceClient, LogglyApiClientOptions } from '../../types';
import { LevelLabel, Transport, TransportContext } from '../../types';
import { isBrowser } from '../../utils';
import { createLogglyApiClient } from './logglyApiClient';

export type LogglyTransportOptions = LogglyApiClientOptions & {
  level?: LevelLabel;
};

export class Loggly implements Transport {
  level?: LevelLabel;
  apiClient: ApiServiceClient = {
    sendEvent: function noopClient(): Promise<void> {
      return Promise.resolve();
    },
  };

  constructor(options: LogglyTransportOptions) {
    if (isBrowser()) {
      return;
    }

    const { level, ...logglyApiClientOptions } = options;
    this.level = level;
    this.apiClient = createLogglyApiClient(logglyApiClientOptions);
  }

  log({ formattedData }: TransportContext): void {
    if (isBrowser()) {
      return;
    }

    this.send(formattedData);
  }

  async send(data: unknown): Promise<void> {
    try {
      await this.apiClient.sendEvent(data);
    } catch (error) {
      console.error(error);
    }
  }
}

export default Loggly;
