import cn from 'classnames';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import CardsContainer from 'components/BaseHelpers/CardsContainer';

import { ComponentProps } from '../../../types/component-props';
import { CoreContentCardsData } from '../../../types/components';

export type CoreContentCardsProps = ComponentProps & CoreContentCardsData;

const CoreContentCards = (props: CoreContentCardsProps): JSX.Element => {
  const { headline, cards } = props.fields;

  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  if (!headline || cards.length === 0) return <></>;

  return (
    <div className={cn('spacer-L', 'container-12')} ref={scrollTriggerRef}>
      <GsapTimelineProvider timeline={timeline}>
        <CardsContainer {...props.fields} />
      </GsapTimelineProvider>
    </div>
  );
};

export default CoreContentCards;
