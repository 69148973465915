import { useGSAP } from '@gsap/react';
import cn from 'classnames';
import { useRef, useState, createElement } from 'react';
import 'react';

import { useGsapTimeline } from 'src/hooks/useGSAPTimeline';

import { gsap, CustomEase } from 'src/utils/gsap';

import styles from './AnimateInContainer.module.scss';

export type AnimateInContainerProps = {
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
  children: React.ReactNode;
  position?: gsap.Position;
  stagger?: number;
  checkForImages?: boolean;
};

const AnimateInContainer = ({
  children,
  position,
  tag = 'div',
  className,
  stagger = 0,
  checkForImages = false,
}: AnimateInContainerProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { addToTimeline, masterTimeline } = useGsapTimeline();
  const [played, setPlayed] = useState<boolean>(false);

  useGSAP(
    () => {
      if (played || process.env.IS_GHSTORYBOOK) {
        return;
      }
      //Set the gsap styles before we have a master timeline so there is no flash of content
      let animation;
      const childrenSelector = `[data-gsap-selector] > *`;
      gsap.set(containerRef.current, {
        opacity: 1,
      });

      CustomEase.create('cubic', '0.65,0,0.35,1');

      // Check for images and animate them differently if they exist
      const mediaElements = checkForImages
        ? containerRef.current?.querySelectorAll('img, video') || []
        : [];

      // Case where we want to animate an image elements
      if (mediaElements.length > 0) {
        gsap.set(mediaElements, { scale: 1.3 });
        animation = gsap.to(mediaElements, {
          scale: 1,
          ease: 'cubic',
          duration: 2,
        });
        addToTimeline(animation, position);
      } else {
        gsap.set(childrenSelector, { opacity: 0, y: 65 });
        if (masterTimeline) {
          animation = gsap.to(childrenSelector, {
            opacity: 1,
            y: 0,
            ease: 'cubic',
            duration: 1.1,
            stagger: stagger,
          });
        }
        if (animation) {
          addToTimeline(animation, position);
          setPlayed(true);
        }
      }
    },
    { dependencies: [masterTimeline], scope: containerRef }
  );

  const classesString = cn(styles.main, className);
  return createElement(
    tag,
    {
      ref: containerRef,
      className: classesString,
      'data-gsap-selector': '',
    },
    children
  );
};

export default AnimateInContainer;
