import cn from 'classnames';
import { forwardRef } from 'react';

import BaseCta, { BaseCtaProps } from '../BaseCta';

import styles from './TextCta.module.scss';

export type TextCtaProps = BaseCtaProps & {
  label: string | undefined;
  font?: 'body-p' | 'footer-p' | 'body-soleil';
};

const TextCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, TextCtaProps>(
  function TextCtaComponent(props: TextCtaProps, ref): JSX.Element {
    const { label, font = 'body-p' } = props || {};
    if (!label) {
      return <></>;
    }
    if (!props.tag || props.tag === 'a') {
      const { target, className, ...otherAnchorProps } = props;
      return (
        <BaseCta
          className={cn(styles.main, styles[font], className)}
          target={target}
          {...otherAnchorProps}
          ref={ref}
        >
          {label}
        </BaseCta>
      );
    }
    if (props.tag === 'button' || props.tag === 'span') {
      const { className, ...otherButtonProps } = props;
      return (
        <BaseCta
          ref={ref}
          {...otherButtonProps}
          className={cn(className, styles[font], styles.main)}
        >
          {label}
        </BaseCta>
      );
    }

    return <></>;
  }
);

export default TextCta;
