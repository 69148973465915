import cn from 'classnames';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import CTA, { CTAProps } from 'components/BaseHelpers/CTA';
import Image from 'components/BaseHelpers/Image';

import { CoreSplitCallToActionData } from '../../../types/components';

import styles from './CoreSplitCallToAction.module.scss';

export type CoreSplitCallToActionProps = CoreSplitCallToActionData;

const CoreSplitCallToAction = (props: CoreSplitCallToActionProps): JSX.Element => {
  const { variant, headline, headingSize, subHeadline, description, image, backgroundColor, cta } =
    props.fields;

  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  const coreCTAData: CTAProps = {
    headline,
    subHeadline,
    description,
    cta,
    backgroundColor,
    largeHeading: headingSize === 'large',
  };

  if (!headline) return <></>;

  return (
    <div ref={scrollTriggerRef} className={cn(styles.wrapper, styles[backgroundColor])}>
      <GsapTimelineProvider timeline={timeline}>
        <div className={cn(styles.main, styles[variant], 'spacer', 'container-12')}>
          {image && (
            <AnimateInContainer
              position={0}
              checkForImages={true}
              className={styles['image-container']}
            >
              <Image {...image} fill={true} />
            </AnimateInContainer>
          )}
          <div className={styles.CTA}>
            <CTA {...coreCTAData} />
          </div>
        </div>
      </GsapTimelineProvider>
    </div>
  );
};

export default CoreSplitCallToAction;
