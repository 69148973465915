import Head from 'next/head';

import { CoreMetadataData } from '../../../types/components';

export type CoreMetadataProps = CoreMetadataData;

const CoreMetadata = (props: CoreMetadataProps): JSX.Element => {
  const {
    title,
    description,
    keywords,
    openGraphUrl,
    openGraphDescription,
    openGraphImage,
    openGraphTitle,
    canonicalUrl,
    twitterDescription,
    twitterImage,
    twitterTitle,
  } = props.fields;

  return (
    <Head>
      <title>{title}</title>

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={openGraphUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={openGraphTitle} />
      <meta property="og:description" content={openGraphDescription} />

      {openGraphImage && <meta property="og:image" content={openGraphImage} />}

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />

      {twitterImage && <meta name="twitter:image" content={twitterImage} />}

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Head>
  );
};

export default CoreMetadata;
