import cn from 'classnames';

import BaseCta from 'components/BaseHelpers/BaseCta';
import PillCTA from 'components/BaseHelpers/PillCTA';
import RichText from 'components/BaseHelpers/RichText';
import SocialSVG from 'components/BaseHelpers/SocialSVG';
import Text from 'components/BaseHelpers/Text';
import TextCta from 'components/BaseHelpers/TextCta';

import { CoreGlobalNavigationHeaderFieldsWithoutLogo } from '../MobileMenu/MobileMenu';

import styles from './MobileContent.module.scss';

export type MobileContentProps = CoreGlobalNavigationHeaderFieldsWithoutLogo & {
  closeMenu: () => void;
};

const MobileContent = (props: MobileContentProps): JSX.Element => {
  const { primaryNav, contact, accountButton, socialMediaNav, closeMenu } = props;

  const hasPrimNav = primaryNav.length > 0;

  const hasSocialMedia =
    socialMediaNav.length > 0 && socialMediaNav.map((item) => item.platform && item.url);

  return (
    <div className={cn(styles.main)}>
      <div className={cn(styles['container'], 'spacer', 'container-10')}>
        {hasPrimNav && (
          <div className={styles['links']}>
            {primaryNav.map((item, index) => {
              return (
                <BaseCta
                  onClick={closeMenu}
                  className={styles.cta}
                  key={index}
                  tag="a"
                  href={item.url}
                >
                  <Text field={item.label} />
                </BaseCta>
              );
            })}
          </div>
        )}
        {hasPrimNav && <div className={styles.divider} />}
        <PillCTA
          className={styles.account}
          theme="dark"
          variant="transparent"
          icon="user"
          {...accountButton}
          tag="a"
        />
        <div className={styles.lower}>
          <Text field={contact.label} className={styles.label} />
          <TextCta font="body-soleil" tag="a" {...contact.cta} />
          <Text field={contact.companyName} />
          <RichText field={contact.address} />
          {hasSocialMedia && (
            <div className={styles.socials}>
              {socialMediaNav.map((item, index) => {
                return <SocialSVG key={index} {...item} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileContent;
