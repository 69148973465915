import cn from 'classnames';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Image from 'components/BaseHelpers/Image';
import PillCTA from 'components/BaseHelpers/PillCTA';
import Text from 'components/BaseHelpers/Text';

import { CoreHeroData } from '../../../types/components';

import styles from './CoreHero.module.scss';

export type CoreHeroProps = CoreHeroData;

const CoreHero = (props: CoreHeroProps): JSX.Element => {
  const { headline, subheadline, cta, format, image, video, setMinimumHeight } = props.fields;
  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  return (
    <div
      ref={scrollTriggerRef}
      className={cn(
        styles.wrapper,
        { [styles['wrapper--setMinHeight']]: setMinimumHeight },
        format === 'color'
          ? styles[`wrapper--${props.fields.backgroundColor}`]
          : styles['wrapper--media']
      )}
    >
      <GsapTimelineProvider timeline={timeline}>
        {format === 'image' && image && <Image className={styles.media} fill={true} {...image} />}
        {format === 'video' && video && (
          <video
            playsInline
            autoPlay
            muted
            loop
            className={cn(styles.media, styles['media--video'])}
          >
            <source src={video.url} type="video/mp4" />
          </video>
        )}
        <div className={cn(styles['main--wrapper'], 'container-12')}>
          <AnimateInContainer stagger={0.4} className={cn(styles.main)}>
            <Text className={styles.headline} tag="h1" field={headline} />
            <Text className={styles.subHeadline} field={subheadline} />
            <PillCTA className={styles.cta} tag="a" {...cta} />
          </AnimateInContainer>
        </div>
      </GsapTimelineProvider>
    </div>
  );
};

export default CoreHero;
