import cn from 'classnames';

import { LinkData } from 'src/types/components';

import AnimateInContainer from '../AnimateInContainer';
import PillCTA from '../PillCTA';
import RichText from '../RichText';
import Text from '../Text';

import styles from './CTA.module.scss';

export type CTAProps = {
  headline: string;
  subHeadline: string;
  description: string;
  largeHeading: boolean;
  backgroundColor: 'dark_mode' | 'light_mode';
  cta: LinkData;
};

const CTA = (props: CTAProps): JSX.Element => {
  const {
    headline,
    subHeadline,
    description,
    cta,
    backgroundColor,
    largeHeading = false,
  } = props || {};

  // MUST be consumed by a component that is wrapped with GsapTimelineProvider

  return (
    <div className={cn(styles.main, styles[backgroundColor])}>
      <AnimateInContainer position={0} className={styles['content-container']} stagger={0.4}>
        <Text
          tag="h2"
          className={cn(styles.headline, { [styles['headline--large']]: largeHeading })}
          field={headline}
        />
        <Text tag="div" className={styles.subheadline} field={subHeadline} />
        <RichText className={styles.desc} field={description} />
        <PillCTA
          className={styles.cta}
          label={cta.label}
          href={cta.href}
          target={cta.target}
          tag={'a'}
        />
      </AnimateInContainer>
    </div>
  );
};

export default CTA;
