import cn from 'classnames';

import ArrowRight from '../../../assets/icons/arrow-right.svg';
import PlayIcon from '../../../assets/icons/play.svg';
import UserIcon from '../../../assets/icons/user.svg';
import BaseCta, { BaseCtaProps } from '../BaseCta';

import styles from './PillCTA.module.scss';

export type PillCTAProps = BaseCtaProps & {
  className?: string;
  label?: string;
  /**
   * `variant` is defaulted to pill
   */
  variant?: 'pill' | 'transparent' | 'transparent-pill';
  /**
   * `theme` is defaulted to light
   */
  theme?: 'light' | 'dark';
  /**
   * `size` is defaulted to large
   */
  size?: 'small' | 'large';
  /**
   * `icon` is only used on transparent CTAs
   */
  icon?: 'arrow' | 'user' | 'play';
  greyed?: boolean;
};

const PillCTA = (props: PillCTAProps): JSX.Element => {
  const { variant = 'pill', theme = 'light', size = 'large', className, label, tag, icon } = props;

  if (!label) {
    return <></>;
  }

  if (tag === 'a') {
    const { target, greyed = false, ...otherAnchorProps } = props;
    return (
      <BaseCta
        target={target}
        {...otherAnchorProps}
        className={cn(styles.main, styles[variant], styles[theme], styles[size], className, {
          [styles['main--greyed']]: greyed,
        })}
      >
        {icon === 'user' && (
          <UserIcon
            className={cn(styles['icon-left'], { [styles['icon-left--small']]: size === 'small' })}
          />
        )}
        {icon === 'play' && (
          <PlayIcon
            className={cn(styles['icon-left'], { [styles['icon-left--small']]: size === 'small' })}
          />
        )}
        {label}
        {icon === 'arrow' && (
          <ArrowRight
            className={cn(styles['icon-right'], {
              [styles['icon-right--small']]: size === 'small',
            })}
          />
        )}
      </BaseCta>
    );
  }
  if (tag === 'button' || tag === 'span') {
    const { greyed = false, ...otherProps } = props;

    return (
      <BaseCta
        {...otherProps}
        className={cn(styles.main, styles[variant], styles[theme], className, {
          [styles['main--greyed']]: greyed,
        })}
      >
        {label}
        {icon === 'arrow' && <ArrowRight className={styles.icon} />}
      </BaseCta>
    );
  }
  return <></>;
};

export default PillCTA;
