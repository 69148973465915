import cn from 'classnames';

import { useRouteContext } from 'src/hooks/useRouteContext';

import BaseCta from 'components/BaseHelpers/BaseCta';
import Image from 'components/BaseHelpers/Image';
import Link from 'components/BaseHelpers/Link';
import PillCTA from 'components/BaseHelpers/PillCTA';
import Text from 'components/BaseHelpers/Text';

import { CoreGlobalNavigationHeaderData } from '../../../types/components';
import MobileMenu, {
  CoreGlobalNavigationHeaderFieldsWithoutLogo,
} from './components/MobileMenu/MobileMenu';

import styles from './CoreNavigationHeader.module.scss';

export type CoreNavigationHeaderProps = CoreGlobalNavigationHeaderData;

const CoreNavigationHeader = (props: CoreNavigationHeaderProps): JSX.Element => {
  const { logo, primaryNav, accountButton, contact, socialMediaNav } = props.fields;
  const { routeData } = useRouteContext();

  const fieldsWithoutLogo: CoreGlobalNavigationHeaderFieldsWithoutLogo = {
    primaryNav,
    accountButton,
    contact,
    socialMediaNav,
  };

  return (
    <header className={styles.main}>
      <div className={cn(styles.wrapper, 'container-12')}>
        <span className={styles.left}>
          <a href="#main" className={styles['skip-link']}>
            {'Skip to main content'}
          </a>
          {logo &&
            (routeData?.route.path === '/' ? (
              <h1 aria-label="Nixon Peabody Trust Company" className={styles['img-container']}>
                <Image fill={true} {...logo} />
              </h1>
            ) : (
              <Link
                href="/"
                aria-label="Go to Nixon Peabody Trust Company Homepage"
                className={styles['img-container']}
              >
                <Image fill={true} {...logo} />
              </Link>
            ))}
          <div className={styles['links']}>
            {primaryNav.map((item, index) => {
              const isActiveTab = item.url === routeData?.route.path;

              return (
                <BaseCta
                  className={cn(styles.cta, { [styles['cta--active']]: isActiveTab })}
                  key={index}
                  tag="a"
                  href={item.url}
                >
                  <Text className={styles['nav-text']} field={item.label} />
                </BaseCta>
              );
            })}
          </div>
        </span>

        <PillCTA
          className={styles.account}
          variant="transparent"
          icon="user"
          {...accountButton}
          tag="a"
        />
        <MobileMenu {...fieldsWithoutLogo} />
      </div>
    </header>
  );
};

export default CoreNavigationHeader;
