import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import CustomEase from 'gsap/dist/CustomEase';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import SplitText from 'gsap/dist/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText, useGSAP, CustomEase);

export * from 'gsap';
export { ScrollTrigger, CustomEase, SplitText, useGSAP };
