import { Formatter, FormatterContext, LevelLabel } from '../types';
import { isPlainObject } from '../utils';

type FormattedJson = Record<string, unknown> & {
  timestampMs: number;
  timestamp: string;
  level: LevelLabel;
  environment: string;
  data: Record<string, unknown>;
};

export class Json implements Formatter {
  format({ data, level, metaData }: FormatterContext): FormattedJson {
    const now = new Date();

    const json: FormattedJson = Object.assign(
      {},
      {
        timestampMs: now.getTime(),
        timestamp: now.toISOString(),
        level: level.label,
        environment: 'server',
        data: {},
      },
      metaData
    );

    if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
      json.data = {
        message: data,
      };
    } else if (data instanceof Error) {
      json.data = {
        name: data.name,
        message: data.message,
        stack: data.stack,
      };
    } else if (isPlainObject(data)) {
      json.data = {
        ...structuredClone(data),
      };
    }

    return json;
  }
}
