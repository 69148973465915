import NextLink from 'next/link';
import { ForwardedRef, forwardRef, AnchorHTMLAttributes } from 'react';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string;
  ref?: ForwardedRef<HTMLAnchorElement>;
  href: string;
  ariaLabel?: string;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function LinkComponent(
  props: LinkProps,
  ref
): JSX.Element {
  const { target, children, className, ariaLabel, ...otherProps } = props || {};
  if (target === '_blank') {
    return (
      <a
        aria-label={ariaLabel}
        ref={ref}
        target="_blank"
        className={className}
        rel={'noreferrer'}
        {...otherProps}
      >
        {children}
      </a>
    );
  }
  return (
    <NextLink aria-label={ariaLabel} className={className} ref={ref} {...otherProps}>
      {children}
    </NextLink>
  );
});

export default Link;
