import cn from 'classnames';
import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import { useOnResize } from 'src/hooks/useOnResize';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import Image from 'components/BaseHelpers/Image';
import PillCTA from 'components/BaseHelpers/PillCTA';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { CoreQuoteSliderData } from '../../../types/components';
import { useGSAP, gsap, SplitText } from '../../../utils/gsap';
import SliderNavigation from './components/SliderNavigation';

import styles from './CoreQuoteSlider.module.scss';

export type CoreQuoteSliderProps = CoreQuoteSliderData;

const CoreQuoteSlider = (props: CoreQuoteSliderProps): JSX.Element => {
  const { backgroundColor, quotes } = props.fields;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [localHasTriggered, setLocalHasTriggered] = useState<boolean>(false);
  const [readyToGo, setReadyToGo] = useState<boolean>(true);

  const [isCooldown, setIsCooldown] = useState<boolean>(false);

  const { scrollTriggerRef, hasTriggered } = useScrollTriggerTimeline();

  const sliderNavRef = useRef<HTMLDivElement>(null);
  const timelines = useRef<GSAPTimeline[]>([]);
  const splitTexts = useRef<SplitText[]>([]);

  const quoteOuterRef = useRef<HTMLDivElement>(null);

  const { contextSafe } = useGSAP({ scope: scrollTriggerRef, dependencies: [] });

  useEffect(() => {
    if (hasTriggered) {
      timelines.current[activeIndex].play();
    }

    if (hasTriggered && readyToGo) {
      timelines.current[activeIndex].play();
    }
    setReadyToGo(false);
  }, [readyToGo, hasTriggered]);

  // height of navbar
  const topNavHeight = 112;
  const scrollToTop = () => {
    if (scrollTriggerRef.current) {
      window.scrollTo({
        top: scrollTriggerRef.current.getBoundingClientRect().top + window.scrollY - topNavHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleNext = contextSafe(() => {
    if (!isCooldown) {
      timelines.current[activeIndex].progress(0);

      let nextIndex: number;
      if (activeIndex < quotes.length - 1) {
        nextIndex = activeIndex + 1;
      } else {
        nextIndex = 0;
      }
      timelines.current[nextIndex].restart();
      setActiveIndex(nextIndex);
      setIsCooldown(true);
      scrollToTop();
      setTimeout(() => {
        setIsCooldown(false);
      }, 1000);
    }
  });

  const handlePrev = contextSafe(() => {
    if (!isCooldown) {
      let nextIndex: number;
      timelines.current[activeIndex].progress(0);

      if (activeIndex > 0) {
        nextIndex = activeIndex - 1;
      } else {
        nextIndex = quotes.length - 1;
      }
      timelines.current[nextIndex].restart();
      setActiveIndex(nextIndex);
      setIsCooldown(true);
      scrollToTop();
      setTimeout(() => {
        setIsCooldown(false);
      }, 1000);
    }
  });

  useOnResize(
    debounce(
      () => {
        createAnimation();
      },
      500,
      { trailing: true, leading: false }
    )
  );

  const createAnimation = contextSafe(() => {
    const tls = quotes.map((quote, index) => {
      const tl = gsap.timeline({
        stagger: 0.5,
        paused: true,
        onComplete: () => setLocalHasTriggered(true),
      });

      // setting img styles if img exists

      const hasImg = quote.image?.src;
      const hasCta = quote.cta.href && quote.cta.label;

      hasImg &&
        gsap.set(`[data-index="${index}"] .${styles['img']}`, {
          opacity: 0,
          scale: 1.3,
          duration: 0,
        });

      // reverting splittext if it exists, then reinitializing it and setting it to current splitText ref

      if (splitTexts.current[index]) {
        splitTexts.current[index].revert();
      }
      const splitLines = new SplitText(`[data-index="${index}"] .${styles['quote']}`, {
        type: 'lines',
        linesClass: styles['lines'],
      });

      splitTexts.current[index] = splitLines;

      gsap.set(splitLines.lines, {
        opacity: 0,
        yPercent: 50,
      });

      // setting attribution styles

      gsap.set(`[data-index="${index}"] .${styles['attribution']}`, { opacity: 0, yPercent: 50 });

      // setting cta styles

      if (hasCta) {
        gsap.set(`[data-index="${index}"] .${styles['cta']}`, { opacity: 0, yPercent: 50 });
      }

      // animating image

      hasImg &&
        tl.to(`[data-index="${index}"] .${styles['img']}`, {
          opacity: 1,
          duration: 1,
          scale: 1,
          ease: 'power2.inOut',
        });

      // animating description lines

      tl.to(splitLines.lines, {
        opacity: 1,
        yPercent: 0,
        stagger: 0.1,
        duration: 0.5,
        ease: 'power2.inOut',
      });

      // animating attri

      tl.to(`[data-index="${index}"] .${styles['attribution']}`, {
        opacity: 1,
        yPercent: 0,
        ease: 'power2.inOut',
      });

      // animating CTA

      if (hasCta) {
        tl.to(`[data-index="${index}"] .${styles['cta']}`, {
          opacity: 1,
          yPercent: 0,
          ease: 'power2.inOut',
        });
      }

      setReadyToGo(true);
      return tl;
    });

    timelines.current = tls;
  });

  useGSAP(
    () => {
      // to get past color contrast in storybook
      if (process.env.IS_GHSTORYBOOK) {
        return;
      }
      createAnimation();
    },
    { dependencies: [], scope: scrollTriggerRef }
  );

  return (
    <div ref={scrollTriggerRef} className={cn(styles.main, styles[backgroundColor], 'spacer-L')}>
      <div className={cn(styles['container'], 'container-12')}>
        <div
          className={cn(
            styles['media-container'],
            { [styles['media-container--noImg']]: !quotes[activeIndex].image?.src },
            { [styles['media-container--img']]: quotes[activeIndex].image?.src }
          )}
        >
          {quotes.map(
            (quote, index) =>
              quote.image &&
              quote.image.src !== '' && (
                <div
                  key={index}
                  className={cn(styles['media-outer'], {
                    [styles['media-outer--active']]: activeIndex === index,
                  })}
                  data-index={index}
                >
                  <div
                    className={cn(styles['media-inner'], {
                      [styles['media-inner--notActive']]: index !== activeIndex,
                    })}
                  >
                    <div className={cn(styles['media-second-inner'])}>
                      <Image
                        fill={true}
                        className={cn(styles.img, {
                          [styles['img--active']]: index === activeIndex,
                          [styles['img--inactive']]: index !== activeIndex,
                        })}
                        {...quote.image}
                      />
                    </div>
                  </div>
                </div>
              )
          )}
        </div>

        <div
          className={cn(
            styles['quote-container'],
            { [styles['quote-container--noImg']]: !quotes[activeIndex].image?.src },
            { [styles['quote-container--img']]: quotes[activeIndex].image?.src }
          )}
        >
          <div ref={quoteOuterRef} className={styles['quote--outer']}>
            {quotes.map((quote, index) => {
              const name = quote.firstName + ' ' + quote.lastName;

              return (
                <div
                  key={index}
                  className={cn(styles['quote-inner'], {
                    [styles['quote-inner--active']]: index === activeIndex,
                  })}
                  data-index={index}
                >
                  <RichText field={quote.quote} data-index={index} className={styles.quote} />
                  {(quote.firstName || quote.lastName || quote.titleClientCompany) && (
                    <div data-index={index} className={styles.attribution}>
                      <Text field="—" className={cn(styles.name, styles.dash)} />
                      <div className={styles['attribution--inner']}>
                        <Text field={name} className={cn(styles.name, styles.attr)} />
                        <Text
                          field={quote.titleClientCompany}
                          className={cn(styles.clientCompany, styles.attr)}
                        />
                      </div>
                    </div>
                  )}
                  <PillCTA data-index={index} tag="a" className={styles.cta} {...quote.cta} />
                </div>
              );
            })}
          </div>

          {quotes.length > 1 && (
            <div
              ref={sliderNavRef}
              className={cn(styles['slider-nav-wrapper'], {
                [styles['slider-nav-wrapper--hasTriggered']]: localHasTriggered,
              })}
            >
              <SliderNavigation
                onPrev={handlePrev}
                onNext={handleNext}
                activeIndex={activeIndex}
                totalSlides={quotes.length}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoreQuoteSlider;
