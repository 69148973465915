export const updateError = (error: Error, data: { message?: string; stack?: string }) => {
  const { message, stack } = data;
  const clone = structuredClone(error);
  if (message) {
    clone.message = `${message}\n${clone.message};`;
  }
  if (stack) {
    clone.stack = `${stack}\n${clone.stack}`;
  }
  return clone;
};
