import cn from 'classnames';
import { useId } from 'react';

import Text from 'components/BaseHelpers/Text';

import styles from './InputField.module.scss';

export type InputFieldProps = {
  label: string;
  placeholderText: string;
  required?: boolean;
  type?: 'text' | 'email' | 'textarea';
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const InputField = (props: InputFieldProps): JSX.Element => {
  const { label, placeholderText, required = false, type = 'text', onChange, value } = props || {};
  const maxCharacters = 1000;
  const charactersRemaining = maxCharacters - (value?.length || 0);
  const labelValue = required ? label + ' (required)' : label;
  const labelForId = useId();

  return (
    <div
      className={cn(styles.main, {
        [styles['main--textarea']]: type === 'textarea',
      })}
    >
      {type === 'textarea' ? (
        <textarea
          id={labelForId}
          placeholder={placeholderText}
          maxLength={maxCharacters}
          required={required}
          className={styles.textarea}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          id={labelForId}
          type={type} // 'text' or 'email'
          placeholder={placeholderText}
          value={value}
          required={required}
          className={styles.input}
          onChange={onChange}
        />
      )}
      <label htmlFor={labelForId} className={styles.label}>
        <Text field={labelValue} className={styles['label-text']} />
        {type === 'textarea' && (
          <Text
            className={cn(styles['label-text'], {
              [styles['label-text--error']]: charactersRemaining === 0,
            })}
            field={`${charactersRemaining.toLocaleString()} characters remaining`}
          />
        )}
      </label>
    </div>
  );
};

export default InputField;
