import cn from 'classnames';

import Link from 'components/BaseHelpers/Link';
import RichText from 'components/BaseHelpers/RichText';
import SocialSVG from 'components/BaseHelpers/SocialSVG';
import Text from 'components/BaseHelpers/Text';
import TextCta from 'components/BaseHelpers/TextCta';

import { CoreGlobalNavigationFooterData } from '../../../types/components';

import styles from './CoreNavigationFooter.module.scss';

export type CoreNavigationFooterProps = CoreGlobalNavigationFooterData;

const CoreNavigationFooter = (props: CoreNavigationFooterProps): JSX.Element => {
  const { socialMediaNav, primaryNav, contact, signUp, notices, ancillaryNav, copyright } =
    props.fields;

  const hasSocialMedia =
    socialMediaNav.length > 0 && socialMediaNav.map((item) => item.platform && item.url);
  const hasPrimaryNav = primaryNav.length > 0 && primaryNav.map((item) => item.label && item.url);
  const hasContact = contact.label && contact.address && contact.companyName;
  const hasSignUp = signUp.label;
  const hasNotices = notices.label;
  const hasAncillary =
    ancillaryNav.length > 0 && ancillaryNav.map((item) => item.label && item.href);

  return (
    <footer className={styles.main}>
      <div className={cn(styles.wrapper, 'container-12', 'spacer')}>
        <div className={styles.top}>
          {/* container of elements to be flex-direction col on mobile */}
          <div className={styles['mobile-top__container']}>
            {/* social svgs */}
            {hasSocialMedia && (
              <div className={cn(styles.socials, styles.section)}>
                {socialMediaNav.map((item, index) => {
                  return <SocialSVG key={index} color="white" {...item} />;
                })}
              </div>
            )}
            {/* primary nav */}
            {hasPrimaryNav && (
              <div className={cn(styles.primaryNav, styles.section)}>
                {primaryNav.map((item, index) => {
                  return (
                    <Link key={index} href={item.url} className={styles['primaryNav__item']}>
                      <Text field={item.label} className={styles['primaryNav__item-text']} />
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles['mobile-bottom__container']}>
            {hasContact && (
              <div className={cn(styles['contact'], styles.section)}>
                <Text className={styles['contact__label']} field={contact.label} />
                <TextCta tag="a" {...contact.cta} />
                <div className={styles['contact__company-container']}>
                  <Text className={styles['contact__company']} field={contact.companyName} />
                  <RichText className={styles['contact__address']} field={contact.address} />
                </div>
              </div>
            )}
            {hasSignUp && (
              <div className={cn(styles['signup'], styles.section)}>
                <Text field={signUp.label} className={styles['signup__label']} />
                {signUp.ctas.map((link, index) => {
                  return <TextCta key={index} tag="a" {...link} />;
                })}
              </div>
            )}
            {hasNotices && (
              <div className={cn(styles.notices, styles.section)}>
                <Text className={styles['notices__label']} field={notices.label} />
                {notices.notices.map((link, index) => {
                  return <TextCta tag="a" key={index} {...link} />;
                })}
              </div>
            )}
          </div>
        </div>
        {(hasAncillary || copyright) && (
          <div className={styles.bottom}>
            {hasAncillary && (
              <div className={styles['bottom__ancillary']}>
                {ancillaryNav.map((item, index) => {
                  return (
                    item.label &&
                    item.href && (
                      <span key={index} className={styles.divider}>
                        <TextCta
                          className={styles['ancil-links']}
                          font="footer-p"
                          tag="a"
                          {...item}
                        />
                        <Text field="/" className={styles.slash} />
                      </span>
                    )
                  );
                })}
              </div>
            )}
            <RichText field={copyright} />
          </div>
        )}
      </div>
    </footer>
  );
};

export default CoreNavigationFooter;
