import { EnvVariables } from 'src/types/model';

export const getEnvVariables = (): EnvVariables => {
  return {
    recaptchaKey: process.env.RECAPTCHA_KEY || '',
    googleTagManagerId: process.env.GTM_KEY || '',
    environment: process.env.ENVIRONMENT || 'QA',
    resizeImages: process.env.RESIZE_IMAGES || '',
  };
};
