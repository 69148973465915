import { ImageData, LinkData } from 'src/types/components';

import AnimateInContainer from '../AnimateInContainer';
import Card from '../Card/Card';
import PillCTA from '../PillCTA';
import Text from '../Text';

import styles from './CardsContainer.module.scss';

export type CardsContainerProps = {
  headline: string;
  cta: LinkData;
  cards: {
    image?: ImageData;
    largeImage: boolean;
    eyebrow: string;
    title: string;
    description: string;
    date: string;
    cta: LinkData;
    videoIndicator: boolean;
    seriesTitle: string;
    primaryTag: string;
  }[];
};

const CardsContainer = (props: CardsContainerProps): JSX.Element => {
  const { headline, cta, cards } = props;
  if (!(cards.length > 0)) return <></>;

  // Must be wrapped by a component with a timeline provider! See CoreContentCards for ex

  return (
    <div>
      <div className={styles.main}>
        <AnimateInContainer>
          <Text tag="h4" field={headline} className={styles.headline} />
        </AnimateInContainer>
        <AnimateInContainer
          stagger={cards.length < 7 ? 0.4 : 0}
          className={styles.container}
          tag="ul"
        >
          {cards.map((card, index) => {
            return (
              <li key={index} className={styles.item}>
                <Card {...card} />
              </li>
            );
          })}
        </AnimateInContainer>
        {cta.href && cta.label && (
          <AnimateInContainer position={headline ? 1.1 : 0} className={styles.cta}>
            <PillCTA tag="a" {...cta} />
          </AnimateInContainer>
        )}
      </div>
    </div>
  );
};

export default CardsContainer;
