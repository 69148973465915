import { Tabs } from '@ark-ui/react';
import { useGSAP } from '@gsap/react';
import cn from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

import { useOnMount } from 'src/hooks/useOnMount';

import TabsCardsContainer from 'components/BaseHelpers/TabsCardsContainer';
import Text from 'components/BaseHelpers/Text';

import { useImagesLoaded } from '../../../hooks/useImagesLoaded';
import { CoreCategoryTabsData } from '../../../types/components';
import { gsap, CustomEase } from '../../../utils/gsap';

import styles from './CoreCategoryTabs.module.scss';

export type CoreCategoryTabsProps = CoreCategoryTabsData;

const CoreCategoryTabs = (props: CoreCategoryTabsProps): JSX.Element => {
  const { tabs } = props.fields;
  const [readyToPlay, setReadyToPlay] = useState<boolean>(false);
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const tabsWrapperRef = useRef<HTMLDivElement>(null);

  const { value, handleValueChange } = useTabsValueManager(tabs);
  const tl = useRef<GSAPTimeline | null>(null);

  const scrollTriggerRef = useRef<HTMLDivElement>(null);
  const [inView, setInView] = useState(false);

  const { isImagesLoaded } = useImagesLoaded(scrollTriggerRef);

  useGSAP(
    () => {
      tl.current = gsap.timeline({
        paused: true,
        onComplete: () => setReadyToPlay(true),
        scrollTrigger: {
          trigger: scrollTriggerRef.current,
          onToggle: (scrollTriggerInstance) => {
            setInView(scrollTriggerInstance.isActive);
          },
        },
      });

      CustomEase.create('cubic', '0.65,0,0.35,1');
      gsap.set(tabsWrapperRef.current, { autoAlpha: 0, y: 65 });

      const tabsAnim = gsap.to(tabsWrapperRef.current, {
        autoAlpha: 1,
        y: 0,
        ease: 'cubic',
        duration: 1.1,
      });

      tl.current.add(tabsAnim);
    },
    { scope: scrollTriggerRef }
  );

  useGSAP(
    () => {
      if (tl.current && inView && isImagesLoaded) {
        tl.current.play();
      }
    },
    { scope: scrollTriggerRef, dependencies: [inView, isImagesLoaded] }
  );

  return (
    <div className={cn('spacer-L', 'container-12', styles.main)} ref={scrollTriggerRef}>
      <Tabs.Root activationMode="manual" value={value} onValueChange={handleValueChange}>
        <div ref={tabsWrapperRef} className={styles['tabs-wrapper']}>
          <Tabs.List className={styles['tabs-container']}>
            {tabs.map((tab, index) => {
              return (
                <Tabs.Trigger
                  key={index}
                  className={cn(styles['tab'], {
                    [styles['tab--active']]: value === tab.tabName,
                  })}
                  value={tab.tabName || ''}
                  aria-controls={`tab-${index}`}
                >
                  <Text field={tab.tabName} className={styles['tab-text']} />
                </Tabs.Trigger>
              );
            })}
            <Tabs.Indicator className={styles.indicator} />
          </Tabs.List>
        </div>
        <div className={styles['cards-container']} ref={cardsContainerRef}>
          {tabs.map((tab, index) => {
            const { tabName, ...cardData } = tab;
            return (
              <Tabs.Content id={`tab-${index}`} key={index} value={tab.tabName || ''}>
                <TabsCardsContainer active={readyToPlay && value === tab.tabName} {...cardData} />
              </Tabs.Content>
            );
          })}
        </div>
      </Tabs.Root>
    </div>
  );
};

export default CoreCategoryTabs;

const useTabsValueManager = (tabs: CoreCategoryTabsProps['fields']['tabs']) => {
  const [value, setValue] = useState(tabs[0].tabName || '');

  const router = useRouter();
  const valueToHashEntries = tabs.map((tab, index) => {
    const value = tab.tabName || '';
    // The first tab doesn't need a hash since that is the default tab that is open
    const hash = index === 0 ? '' : tab.tabName || '';
    return [value, kebabCase(hash)] as const;
  });

  const valueToHashMap = new Map(valueToHashEntries);
  const hashToValueMap = new Map(valueToHashEntries.map(([value, hash]) => [hash, value]));

  const getValueFromRouterHash = () => {
    const { hash } = new URL(router.asPath, 'http://this-base-url-doesnt-matter');
    const firstTabValue = tabs[0].tabName || '';

    if (hash.startsWith('#')) {
      const decodedHash = decodeURIComponent(hash.slice(1));
      const value = hashToValueMap.get(decodedHash) || firstTabValue;
      return value;
    } else {
      return firstTabValue;
    }
  };

  const getRouterHashFromValue = (value: string) => {
    const hash = valueToHashMap.get(value) || '';
    return hash;
  };

  const handleValueChange = (changeEvent: { value: string }) => {
    setValue(changeEvent.value);
    const hash = getRouterHashFromValue(changeEvent.value);
    const { pathname } = new URL(router.asPath, 'http://this-base-url-doesnt-matter');
    router.replace({ pathname, hash }, undefined, { scroll: false });
  };

  useOnMount(() => {
    const value = getValueFromRouterHash();
    setValue(value);
  });

  return {
    handleValueChange,
    value,
  };
};
