import cn from 'classnames';

import { CoreContentCardsData } from 'src/types/components';

import Play from '../../../assets/icons/play.svg';
import Image from '../Image';
import Link from '../Link';
import RichText from '../RichText';
import Text from '../Text';

import styles from './Card.module.scss';

export type CardProps = CoreContentCardsData['fields']['cards'][number];

const Card = (props: CardProps): JSX.Element => {
  const {
    image,
    eyebrow,
    title,
    description,
    date,
    cta,
    seriesTitle,
    primaryTag,
    videoIndicator,
    largeImage,
  } = props || {};

  if (!title) return <></>;

  const eyebrowText = eyebrow ? `— ${eyebrow}` : '';

  return (
    <Link {...cta} className={styles.main}>
      {image && image.src && (
        <div
          className={cn(styles['img-container'], { [styles['img-container--large']]: largeImage })}
        >
          {/* this inner container is needed for overflow hidden to be applied to img only, and not the play container */}
          <div className={styles['img--inner']}>
            <Image fill={true} className={styles.img} {...image} />
          </div>
          {videoIndicator && (
            <div className={styles['play-container']}>
              <Play className={styles.play} />
            </div>
          )}
        </div>
      )}
      <div className={styles['content-container']}>
        <div className={styles['heading-container']}>
          <Text className={styles.eyebrow} field={eyebrowText} />
          <Text className={styles['series-title']} field={seriesTitle} />
          <Text className={styles.title} field={title} />
        </div>
        <RichText className={styles.desc} field={description} />
        <Text className={styles['tag-title']} field={primaryTag} />
        <Text className={styles.date} field={date} />
      </div>
    </Link>
  );
};

export default Card;
