import { ApiRequest } from './types';

type RequestMetaData = {
  host?: string;
  url?: string;
  rayId?: string;
  userAgent?: string;
  ip?: string;
};

export const parseRequestMetaData = (req: ApiRequest | undefined): RequestMetaData => {
  if (!req) {
    return {};
  }

  return {
    host: req?.headers['host'],
    url: req?.url,
    rayId: req?.headers['CF-Ray'],
    userAgent: req?.headers['user-agent'],
    ip: req?.ip,
  };
};
