import { GoogleTagManager } from '@next/third-parties/google';
import Head from 'next/head';
import Scripts from 'src/Scripts';

import OneTrust from 'components/BaseHelpers/OneTrust/OneTrust';

import DefaultTemplate from './templates/DefaultTemplate';
import { RouteHandlerReturn } from './types/model';

const Layout = ({ routeData }: { routeData: RouteHandlerReturn }): JSX.Element => {
  const gtmId = routeData.props?.envVars.googleTagManagerId || '';

  const favicon =
    typeof routeData.props?.globalSettings?.favicon?.src === 'string'
      ? routeData.props?.globalSettings?.favicon?.src
      : '/favicon.png';

  return (
    <>
      <Scripts />
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <link rel="icon" href={favicon} type="image/x-icon" />
      </Head>
      <GoogleTagManager gtmId={gtmId} />
      <OneTrust />
      {routeData && <DefaultTemplate key={routeData.props?.route.itemId} routeData={routeData} />}
    </>
  );
};

export default Layout;
