import cn from 'classnames';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Image from 'components/BaseHelpers/Image';
import PillCTA from 'components/BaseHelpers/PillCTA';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { CoreHeroFeaturedContentData } from '../../../types/components';

import styles from './CoreHeroFeaturedContent.module.scss';

export type CoreHeroFeaturedContentProps = CoreHeroFeaturedContentData;

const CoreHeroFeaturedContent = (props: CoreHeroFeaturedContentProps): JSX.Element => {
  const {
    headline,
    subheadline,
    cta,
    format,
    image,
    video,
    featuredOverviewLabel,
    secondaryCta,
    featuredCards,
  } = props.fields;

  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  return (
    <div
      ref={scrollTriggerRef}
      className={cn(
        styles.wrapper,
        format === 'color'
          ? styles[`wrapper--${props.fields.backgroundColor}`]
          : styles['wrapper--media']
      )}
    >
      <GsapTimelineProvider timeline={timeline}>
        {format === 'image' && image && <Image className={styles.media} fill={true} {...image} />}
        {format === 'video' && video && (
          <video
            playsInline
            autoPlay
            muted
            loop
            className={cn(styles.media, styles['media--video'])}
          >
            <source src={video.url} type="video/mp4" />
          </video>
        )}
        <div className={cn(styles['content-wrapper'], 'container-12')}>
          <AnimateInContainer stagger={0.4} className={cn(styles.left)}>
            <Text className={styles.headline} tag="h1" field={headline} />
            <Text className={styles.subHeadline} field={subheadline} />
            <PillCTA className={styles.cta} tag="a" {...cta} />
          </AnimateInContainer>

          {/* potentially abstract this out to a helper component */}

          {featuredCards.length > 0 && (
            <AnimateInContainer className={styles['animate-container']}>
              <div className={styles['featured-overview']}>
                <div className={styles['featured-overview__header']}>
                  <Text
                    className={styles['featured-overview__label']}
                    field={featuredOverviewLabel}
                  />
                  <PillCTA
                    className={styles['featured-overview__cta']}
                    variant="transparent"
                    theme="dark"
                    icon="arrow"
                    tag="a"
                    {...secondaryCta}
                  />
                </div>
                <div className={styles['featured-overview__content']}>
                  {featuredCards.map((card, index) => {
                    return (
                      <div key={index} className={styles['featured-overview__card']}>
                        <div className={styles['featured-overview__card-heading-container']}>
                          <Text
                            className={styles['featured-overview__card-title']}
                            field={card.title}
                          />
                          <Text
                            className={styles['featured-overview__card-subHeadline']}
                            field={card.subHeadline}
                          />
                        </div>
                        <RichText
                          className={styles['featured-overview__card-desc']}
                          field={card.description}
                        />
                        <PillCTA
                          className={styles['featured-overview__card-cta']}
                          tag="a"
                          {...card.cta}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </AnimateInContainer>
          )}
        </div>
      </GsapTimelineProvider>
    </div>
  );
};

export default CoreHeroFeaturedContent;
