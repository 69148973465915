import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Layout from 'src/Layout';
import { getEnvVariables } from 'src/data/utils/getEnvVariables';

import { RouteContextProvider } from 'src/hooks/useRouteContext';

import { logger, updateError } from 'lib/logger';
import { parseRequestMetaData } from 'lib/logger/package';

import { RouteHandlerReturn } from '../types/model';

/**
 * Rendered for 500 errors on both server and client. Used only in Production mode.
 * @link https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
 */

const ErrorPage: NextPage = () => {
  const [pageProps, setPageProps] = useState<RouteHandlerReturn>();
  const [isLoaded, setIsLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const getPageProps = async () => {
      try {
        const url = `/api/pages/error`;
        const response = await fetch(url);
        const props = (await response.json()) as RouteHandlerReturn;

        if (props?.props) {
          setPageProps(props);
        } else {
          throw new Error('Unable to fetch error page');
        }
      } catch (error) {
        setPageProps(fallbackData());
        if (error instanceof Error) {
          logger.error(updateError(error, { message: '_error.tsx' }));
        }
      } finally {
        setIsLoaded(true);
      }
    };

    getPageProps();
  }, [router.locale]);

  if (isLoaded && pageProps) {
    return (
      <RouteContextProvider routeData={pageProps.props}>
        <Layout routeData={pageProps} />
      </RouteContextProvider>
    );
  } else {
    return <></>;
  }
};

ErrorPage.getInitialProps = ({ req, err }) => {
  const childLogger = logger.child(parseRequestMetaData(req));
  childLogger.error(err);
  return { error: err };
};

export default ErrorPage;

const fallbackData = (): RouteHandlerReturn => {
  return {
    props: {
      globalSettings: {
        favicon: {
          src: '/favicon.png',
          alt: '',
        },
      },
      envVars: {
        ...getEnvVariables(),
      },
      route: {
        itemId: -1,
        type: 'page',
        template: 'flexible-template',
        path: '/error',
        slug: 'error',
        fields: {},
        placeholders: {
          header: [
            {
              componentName: 'CoreNavigationHeader',
              fields: {
                logo: {
                  src: '',
                  alt: '',
                },
                primaryNav: [],
                accountButton: {
                  label: '',
                  href: '',
                },
                contact: {
                  label: 'Contact Us',
                  cta: {
                    label: '',
                    href: '',
                  },
                  companyName: 'Nixon Peabody Trust Company',
                  address: 'Exchange Place <br> 53 State Street <br> Boston, MA 02109',
                },
                socialMediaNav: [],
              },
            },
          ],
          content: [
            {
              componentName: 'CoreHero',
              fields: {
                backgroundColor: 'blue',
                format: 'color',
                headline: 'An Error Occured',
                subheadline: 'Please try again later.',
                setMinimumHeight: false,
                cta: {
                  label: '',
                  href: '',
                },
              },
            },
          ],
          footer: [
            {
              componentName: 'CoreNavigationFooter',
              fields: {
                socialMediaNav: [],
                primaryNav: [],
                contact: {
                  label: 'Contact Us',
                  cta: {
                    label: '',
                    href: '',
                  },
                  companyName: 'Nixon Peabody Trust Company',
                  address: 'Exchange Place <br> 53 State Street <br> Boston, MA 02109',
                },
                signUp: {
                  label: '',
                  ctas: [],
                },
                notices: {
                  label: 'Notices',
                  notices: [],
                },
                ancillaryNav: [],
                copyright: '',
              },
            },
          ],
        },
      },
    },
    notFound: false,
  };
};
