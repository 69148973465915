import { ApiServiceClient, LogglyApiClientOptions } from '../../types';

const trimTags = (tags: string) =>
  tags
    .split(',')
    .map((tag) => tag.trim())
    .join(',');

export const createLogglyApiClient = (options: LogglyApiClientOptions): ApiServiceClient => {
  const errorMessage = 'LOGGLY_TOKEN env variable required to send logs to loggly';

  if (!options?.token) {
    console.error(errorMessage);
  }

  const token = options?.token;
  const subdomain = options?.subdomain;
  const tags = trimTags(options?.tags);

  const url = `https://${subdomain}.loggly.com/inputs/${token}/tag/${tags}/`;

  return {
    async sendEvent(json: unknown): Promise<void> {
      if (!token) {
        return Promise.reject(new Error(errorMessage));
      }

      try {
        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(json),
        });
        return;
      } catch (error) {
        return error instanceof Error
          ? Promise.reject(error)
          : Promise.reject(new Error('Failed to send event to loggly'));
      }
    },
  };
};
