import cn from 'classnames';

import { GsapTimelineProvider } from 'src/hooks/useGSAPTimeline';
import { useScrollTriggerTimeline } from 'src/hooks/useScrollTriggerTimeline';

import { CoreCallToActionData } from 'src/types/components';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Image from 'components/BaseHelpers/Image';
import PillCTA from 'components/BaseHelpers/PillCTA';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './CoreCallToAction.module.scss';

export type CoreCallToActionProps = CoreCallToActionData;

const CoreCallToAction = (props: CoreCallToActionProps): JSX.Element => {
  const { headline, description, cta, background } = props.fields;

  const { timeline, scrollTriggerRef } = useScrollTriggerTimeline();

  if (!headline) return <></>;

  return (
    <div ref={scrollTriggerRef} className={cn(styles.main, styles[background])}>
      <div className={cn('container-12', 'spacer-L')}>
        <Image
          alt=""
          src={'/images/D_FeatureCTA_BG-transparent.png'}
          className={styles['img-desktop']}
          fill={true}
        />
        <GsapTimelineProvider timeline={timeline}>
          <AnimateInContainer className={cn(styles['content-container'])} stagger={0.4}>
            <Text tag="div" className={styles.headline} field={headline} />
            <RichText className={styles.desc} field={description} />
            <PillCTA
              className={styles.cta}
              label={cta.label}
              href={cta.href}
              target={cta.target}
              tag={'a'}
            />
          </AnimateInContainer>
        </GsapTimelineProvider>
      </div>
    </div>
  );
};

export default CoreCallToAction;
